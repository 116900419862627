import React, { useState } from "react";

/* const color={
    blue: '1F5FD3',
    blue_hover: "262A73",
    red: "ff0000",
    red_hover: "ff4444",
} */
//${props.color === 'red' ? color.red : color.blue} ${props.color === 'red' ? color.red_hover : color.blue_hover}

const Button = (props) => {
  return (
    <>
      {props.size === "xs" ? (
        <button
          id={props.id}
          className={
            " py-[4px] px-[10px] text-[0.815rem] " +
            (props.color === "Error" ? "btnMillerError" : "btnMiller")
          }
          onClick={() => props.onClick()}
        >
          <span className="flex items-center">
            <i className={`bi bi-${props.icon} text-lg`}></i>
          </span>
          {props.text}
        </button>
      ) : (
        ""
      )}
      {props.size === "md" ? (
        <button
          id={props.id}
          className={
            " py-[6px] px-[16px] text-[0.875rem] " + props.color === "Error"
              ? "btnMillerError"
              : "btnMiller"
          }
          onClick={() => props.onClick()}
        >
          <span className="flex items-center">
            <i className={`bi bi-${props.icon} text-xl`}></i>
          </span>
          {props.text}
        </button>
      ) : (
        ""
      )}
      {props.size === "lg" ? (
        <button
          id={props.id}
          className={
            "py-[8px] px-[22px] text-[0.9375rem]   " + props.color === "Error"
              ? "btnMillerError"
              : "btnMiller"
          }
        >
          <span className="flex items-center">
            <i className={`bi bi-${props.icon} text-2xl`}></i>
          </span>
          {props.text}
        </button>
      ) : (
        ""
      )}
    </>
  );
};
export default Button;
