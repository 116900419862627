const TOKEN_KEY = 'jwt';
//const jwt = require('jsonwebtoken');

export const login = (token) => {
    localStorage.setItem('jwt', token);
    sessionStorage.setItem('logged', 'ok');
}

export const isLogin = () => {
    if (sessionStorage.getItem('logged') || localStorage.getItem('logged')  ) {
        return true;
    }

    return false;
}