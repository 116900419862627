import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import { login, loginPersistant } from "../utils";
import { Switcher } from "../utils/Switcher";
import GlobalSnackbar from "./Snackbar";
import Safety from "../assets/images/Safety_sx.jpeg";

const Login = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ user: "", password: "" });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (e) => {
    let personaleData = { ...data };
    personaleData[e.target.name] = e.target.value;
    setData(personaleData);
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleLogin = async (e) => {
    //e.preventDefault();
    const response = await unsplash.post("login", {
      username: data.user,
      password: data.password,
      tipologia: "Clienti",
    });

    if (response.data.utente.data.length > 0) {
      /* if (this.state.isChecked) {
        //per login dove si ricorda l'utente
        loginPersistant(response.data.utente.token);
      } else { */

      login(response.data.utente.token);
      sessionStorage.setItem("S", response.data.utente.data[0].Sicurezza);
      sessionStorage.setItem("F", response.data.utente.data[0].Formazione);
      sessionStorage.setItem("M", response.data.utente.data[0].Medicina);

      sessionStorage.setItem("Cliente", response.data.utente.data[0].Cliente);
      sessionStorage.setItem(
        "Notarizzazione",
        response.data.utente.data[0].Notarizzazione
      );
      sessionStorage.setItem("Email", response.data.utente.data[0].Email);
      sessionStorage.setItem(
        "Dettaglio",
        response.data.utente.data[0].Dettaglio
      );
      sessionStorage.setItem("User", data.user);
      navigate("/Dashboard");
      /* } */
    } else {
      setSnack({
        severity: "error",
        messaggio: "Username o password errati, riprovare!",
      });
      setOpenSnack(true);
      setData({ user: "", password: "" });
    }
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {/* <!-- component --> */}
      <div className="flex w-full h-screen flex-nowrap bg-slate-200 dark:bg-gray-800 relative">
        <Switcher />
        {/* Container img */}
        <div
          className="md:flex hidden justify-center align-middle bg-cover bg-center w-1/2 h-[calc(100vh - 4rem)] m-4 rounded pl-2"
          style={{ backgroundImage: `url(${Safety})` }}
        ></div>
        {/* Container */}
        <div className="flex justify-center align-middle flex-wrap w-full md:w-1/2">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto w-full md:h-screen lg:py-0">
            <div className="w-full md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <form onSubmit={handleLogin} className="space-y-4 md:space-y-6">
                  {/* <!-- text login --> */}
                  <div>
                    <h1 className="text-center text-2xl font-bold text-black dark:text-white  mb-6 z-10">
                      Koala - Accesso Cliente
                    </h1>
                  </div>
                  {/* <!-- user input --> */}

                  <div>
                    <input
                      type="user"
                      name="user"
                      id="user"
                      className="w-full py-4 px-8 bg-white rounded-md ring-1 outline-primary-600 ring-primary-600"
                      placeholder="Username"
                      onChange={(e) => handleChange(e)}
                      value={data.user}
                    />
                  </div>
                  {/* <!-- password input --> */}
                  <div className="relative">
                    <span className="absolute right-0 flex items-center pr-2 h-full">
                      <button
                        type="button"
                        onClick={() => setShow(!show)}
                        className="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          hidden={!show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          hidden={show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                          <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                          <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                        </svg>
                      </button>
                    </span>
                    <input
                      type={show ? "text" : "password"}
                      name="password"
                      id="password"
                      className="w-full py-4 px-8 bg-white rounded-md ring-1 outline-primary-600 ring-primary-600"
                      placeholder="Password"
                      onChange={(e) => handleChange(e)}
                      value={data.password}
                    />
                  </div>
                  {/* <!-- button --> */}
                </form>
                <button
                  className="w-full text-white transition-all bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-100 font-semibold rounded-md text-lg px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={() => handleLogin()}
                >
                  LOG IN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
