import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogin,isToken } from '../utils';

function PrivateRoute({ children }) {
    const logok = isLogin();
    const tokenok = true;//isToken();

    return logok && tokenok ? children : <Navigate to="/login" />;
  }

export default PrivateRoute;
