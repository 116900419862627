import React, { useEffect, useState } from "react";
import ClippedDrawer from "./AppContent";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import unsplash from "../api/unsplash";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Edit,
  Check,
} from "@mui/icons-material";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const dataHeaders = [{ label: "Descrizione" }, { label: "Dimensione" }];

const Medicina = () => {
	const [loadingDoc, setLoadingDoc] = useState(false);


  const columns = [
    { field: "Descrizione", headerName: "Descrizione", width: 800 },
    {
      field: "Azioni",
      headerName: "Azioni",
      hide: false,
      width: "200",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#11526F" }}
            />
          </IconButton>
          {params.row.Estensione === ".pdf" ? (
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.ID,
                  params.row.Estensione,
                  params.row.Descrizione,
                  "visualizza"
                )
              }
            >
              <RemoveRedEye
                style={{ height: "2rem", width: "2rem", color: "#11526F" }}
              />
            </IconButton>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  const [show, setShow] = useState(false);
  const [allegati, setAllegati] = useState([]);
  const [data, setData] = useState([]);
  const [allegato, setAllegato] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let responseAziendali = await unsplash.get("allegati", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          colonna: "IdoneitaUtente",
          user: sessionStorage.getItem("User"),
        },
      });
      setAllegati(responseAziendali.data.data);
    };
    fetchData();
  }, []);
  const downloadFile = async (id, estensione, descrizione, operazione) => {
		try {
			if (loadingDoc) {
		
					alert('Aspetta che la richiesta precedente sia completata prima di iniziare')
	
			} else {
				setLoadingDoc(true);
				// Make the POST request to trigger the download and response from the server
				const response = await unsplash.post(
					'ftp',
					{
						file: id + estensione,
						nomeOriginale: descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione,
						operazione: 'download',
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('jwt')}`,
						},
						responseType: 'blob',
					}
				);
				const url = window.URL.createObjectURL(
					new Blob([response.data], { type: ext[estensione.replace('.', '')] })
				);
				const link = document.createElement('a');
				link.href = url;
				if (operazione === 'download') {
					link.setAttribute(
						'download',
						descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione
					);
					document.body.appendChild(link);
					link.click();
				}

				if (operazione === 'visualizza') {
					if (estensione === '.pdf') {
						window.open(url);
					}
				}
				setLoadingDoc(false);
			}
		} catch (error) {
			let messaggio = 'Errore durante la visualizzazione del file: ';
			if (operazione === 'download') {
				messaggio = 'Errore durante il download del file: ';
			}
      alert(messaggio)
			if (error.response) {
				if (error.response.status === 404) {
					messaggio = messaggio + 'File non trovato.';
				} else if (error.response.status === 500) {
					messaggio =
						messaggio +
						'Errore interno del server. Si prega di riprovare più tardi.';
				} else {
					messaggio = messaggio + 'Errore sconosciuto.';
				}
			} else if (error.request) {
				// La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
				messaggio =
					messaggio +
					'Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.';
			} else {
				// Si è verificato qualche altro errore
				messaggio =
					messaggio +
					'Si è verificato un errore. Si prega di riprovare più tardi.';
			}
      alert(messaggio)
			console.error('Errore durante il download del file:', error);
			setLoadingDoc(false);
		}
	};

  return (
    <>
      {/* <!-- component --> */}

      <div
        className=" flex flex-1 h-screen bg-white p-4  shadow-lg rounded-md border border-slate-200
      "
      >
        <DataGridPro
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: GridToolbar,
          }}
          rows={allegati}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.ID}
        />
      </div>
    </>
  );
};

export default Medicina;
