import React, { useState, useEffect } from "react";
import unsplash from "../api/unsplash";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  LinearProgress,
  Button,
} from "@mui/material";
import GaugeChart from "react-gauge-chart";
import moment from "moment";
import { DataGrid, itIT } from "@mui/x-data-grid";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SchoolIcon from "@mui/icons-material/School";
import ShieldIcon from "@mui/icons-material/Shield";
import {
  Circle,
  Lock,
  CalendarMonth,
  Link,
  Person,
  Info,
} from "@mui/icons-material";
import { CircularProgressbar } from "react-circular-progressbar";
import LockIcon from "@mui/icons-material/Lock";
import { toast, ToastContainer } from "react-toastify";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EuroIcon from "@mui/icons-material/Euro";

import logoChiaro from "../assets/images/Logo-chiaro.png";
import { useNavigate } from "react-router-dom";

import { PieChart, Pie, Cell, Legend } from "recharts";
import { useRef } from "react";

const dataEmpty = [{ name: "Vuoto", value: 0.5, color: "#40C18F" }];
const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const dataTable = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const columnTable = [
  {
    field: "name",
    headerName: "Descrizione",
    flex: 1,
  },
  {
    field: "value",
    headerName: "Valore",
    flex: 1,
  },
];

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "max-content max-content",
        gap: "1em",
      }}
    >
      {payload.map((entry, index) => (
        <>
          <div>{entry.value}</div>
          {/* <div>{measurments}</div> */}
        </>
      ))}
    </div>
  );
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#11526F"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Home = () => {
  const [analisiFormazione, setAnalisiFormazione] = useState(null);
  const [analisiFormazionePie, setAnalisiFormazionePie] = useState(null);
  const [prossimiCorsi, setProssimiCorsi] = useState([]);
  const [prossimeVisite, setProssimeVisite] = useState([]);
  const [utente, setUtente] = useState(null);
  const [handleLocked, setHandleLocked] = useState({
    med: false,
    sic: false,
    form: false,
  });
  const [pieMedicina, setPieMedicina] = useState([]);
  const [sicurezzaPie, setSicurezzaPie] = useState([]);
  const [sicurezzaScadenze, setSicurezzaScadenze] = useState([]);
  const [openMed, setOpenMed] = useState(false);
  const [openFormazione, setOpenformazione] = useState(false);
  const [valMedicina, setValMedicina] = useState(0);
  const [valFormazione, setValFormazione] = useState(0);
  const [valSicurezza, setValSicurezza] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const divContainer = useRef();
  const navigate = useNavigate();
  const columns = [
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataInizio
              ? moment(params.row.DataInizio).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },

    {
      field: "DataFine",
      headerName: "Data Fine",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataFine
              ? moment(params.row.DataFine).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];
  const columnsSicurezza = [
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
    },
  ];

  const columnsVisite = [
    {
      field: "Lavoratore",
      headerName: "Lavoratore",
      flex: 1,
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },

    {
      field: "Accertamenti",
      headerName: "Accertamenti",
      flex: 1,
    },
  ];

  //CALCOLO LARGHEZZA TOTALE WIDTH
  const [screenSize, getDimension] = useState(
    window.innerWidth >= "1024" ? window.innerWidth - 256 : window.innerWidth
  );
  const setDimension = () => {
    getDimension(
      screenSize >= "1024"
        ? divContainer?.current.offsetWidth
        : divContainer?.current.offsetWidth * 3
    );
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        setValMedicina(85);
        setValFormazione(90);
        setValSicurezza(87);

        /*let responseUtenti = await unsplash.get("utentiClienti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
         const utenteWeb = responseUtenti.data.data.filter(
          (el) => sessionStorage.getItem("User") === el.Username
        )[0];
        setUtente(utenteWeb); */

        //SICUREZZA
        let responseSic = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "percentualiAdempimentiUtente",
          },
        });
        setSicurezzaPie(responseSic.data.data.data);

        let responseScadenzeSic = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "scadenzeAdempimentiUtente",
          },
        });

        setSicurezzaScadenze(
          responseScadenzeSic.data.data.filter((el) => {
            let scadenza = el.Scadenza;
            if (!el.UltimaScadenza) {
              return el;
            } else {
              let dateTemp = moment(el.UltimaScadenza).add(scadenza, "M");
              if (
                dateTemp.format("YYYY-MM-DD") <
                moment(new Date()).format("YYYY-MM-DD")
              ) {
                return el;
              }
            }
          })
        );

        //MEDICINA
        let responseViste = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "medicinaPercentualiUtente",
          },
        });
        setPieMedicina(responseViste.data.data.data);

        let responseProssimiVisite = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "prossimeVisiteUtente",
          },
        });

        setProssimeVisite(responseProssimiVisite.data.data);

        //FORMAZIONE
        let responseCorsiScadenza = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "percentualiCorsiInScadenzaUtenteDettaglio",
          },
        });

        setAnalisiFormazione(
          responseCorsiScadenza.data.data.data.filter(
            (el) => el.name !== "In scadenza"
          )
        );

        let responseProssimiCorsi = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "prossimiCorsiUtente",
          },
        });

        const currentDate = new Date();

        const corsiFiltrati = responseProssimiCorsi.data.data.map((el) => {
          var compareDate = moment(el.DataInizio).format("YYYY-MM-DD");

          var startDate = moment(currentDate).format("YYYY-MM-DD");

          var endDate = moment(currentDate).add(3, "M").format("YYYY-MM-DD");

          if (moment(compareDate).isBetween(startDate, endDate)) {
            return el;
          }
        });
        setProssimiCorsi(corsiFiltrati);

        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const askForArea = async (area) => {
    try {
      await unsplash.post(
        "utenti",
        { utente: sessionStorage.getItem("User"), area: area },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      toast.success(
        "Grazie per aver effettuato la richiesta. Verrai contattato il prima possibile",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } catch {
      toast.error("Attenzione! Errore durante la richiesta", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const askForNotarizzazione = async () => {
    alert("MAIL INVIATA");
  };
  return (
    <div className="flex flex-wrap">
      <div className=" bg-primary-600 dark:bg-white p-4 sm:p-7 rounded-md h-fit">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="colored"
        />
        {/* Background illustration */}

        {/* Content */}
        <div className="relative">
          {/* <span className="absolute inset-y-0 right-0 top-0 pointer-events-none z-50 hidden xl:block h-20 w-20 light-logo"></span> */}
          <div className="absolute inset-y-0 right-0 top-0 pointer-events-none z-10 hidden xl:block">
            <img src={logoChiaro} alt="" className="h-20 w-20" />
          </div>
          <h1 className="text-2xl md:text-3xl text-white dark:text-primary-600 font-bold mb-1">
            Benvenuto, {sessionStorage.getItem("User")}.
          </h1>
          <p className="text-white dark:text-primary-600 w-5/6 ">
            Visualizza e gestisci i tuoi livelli di sicurezza direttamente dalla
            dashboard dedicata. Oltre a monitorare l’attività ti suggeriamo
            tutte le azioni necessarie per migliorare la sicurezza della tua
            attività.
          </p>
        </div>
      </div>
{/*
      <div className=" mt-4 flex flex-row h-fit w-full gap-4 flex-wrap lg:flex-nowrap">
        <div
          className="w-full lg:w-1/3 rounded-md p-4 bg-azulMiller text-white hover:opacity-75 cursor-pointer duration-200"
          onClick={askForNotarizzazione}
        >
          {sessionStorage.getItem("Notarizzazione") === "0" ? (
            <>
              <Link className="mr-2 rotate-135 text-redMiller" />
              <b>NOTARIZZAZIONE NON ATTIVA</b>
              <br />
              <p>Clicca qui se vuoi informazioni</p>
            </>
          ) : (
            <>
              <Link className="mr-2 rotate-135 text-greenMiller " />
              <b>NOTARIZZAZIONE ATTIVA</b>
              <br />
              <p>I tuoi documenti vengono notarizzati</p>
            </>
          )}{" "}
        </div>
        <div className="w-full lg:w-1/3 rounded-md p-4 bg-azulMiller font-bold text-white">
          <Person className="mr-2 text-primary-600 " />
          LIVELLO SAFETY <br />
          <div class="flex items-center justify-between my-2">
            <p class="text-white text-sm">Livello 3</p>
          </div>
          <div class="w-full h-2 bg-blue-200 rounded-full">
            <div
              class=" h-full text-center text-xs text-white bg-primary-600 rounded-full"
              style={{ width: "70%" }}
            ></div>
          </div>
        </div>
        <div
          className="w-full lg:w-1/3 rounded-md p-4 bg-azulMiller text-white hover:opacity-75 cursor-pointer duration-200"
          onClick={() => navigate("/Help")}
        >
          <Info className="mr-2 text-primary-600" />
          <b>Hai bisogno di supporto? </b>
          <br />
          <p>
            Clicca qui per segnalarci un problema, ti forniremo assistenza il
            prima possibile.
          </p>
        </div>
      </div>
      <div
        className="mt-4 flex flex-row h-fit w-full gap-4 flex-wrap lg:flex-nowrap"
        id="divContainer"
        ref={divContainer}
      >
        {sessionStorage.getItem("M") === "1" ? (
          <div class="pieTrue">
            <h3 class="pieTitle">
              <MedicalServicesIcon
                fontSize="large"
                className="mr-2 text-primary-600"
              />
              <b>Medicina </b>
            </h3>
            {pieMedicina.length > 0 ? (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                    wrapperStyle={{ fontWeight: "semibold", display: "flex" }}
                  />
                  <Pie
                    data={pieMedicina}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieMedicina.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
<<<<<<< HEAD
=======
             
>>>>>>> 4cfaec6bac8d8755de27bb397ea8e0c4fdafb79f
                </PieChart>
              </>
            ) : (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={dataEmpty}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {dataEmpty.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} />
                </PieChart>
              </>
            )}
            <hr />
            <div className="w-full md:h-72 mt-2">
              <p className="font-light text-white text-center text-md mt-2">
                PROSSIME VISITE
              </p>
              {/*<DataGrid
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={prossimeVisite}
                columns={columnsVisite}
                getRowId={(row) => row.ID}
                hideFooterPagination
                hideFooterSelectedRowCount
                    />
            </div>
          </div>
        ) : (
          <>
            <div class="pieFalse">
              <div className="opacity-50">
                <h3 class="pieTitle">
                  <MedicalServicesIcon
                    fontSize="large"
                    className="mr-2 text-primary-600"
                  />
                  <b>Medicina </b>
                </h3>
              </div>
              <div className="opacity-20">
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={data}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
                <hr />
                <div className="w-full md:h-72 mt-2">
                  <p className="font-light text-white text-center text-md mt-2">
                    PROSSIME VISITE
                  </p>
                  <DataGrid
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={dataTable}
                    columns={columnTable}
                    getRowId={(row) => row.value}
                    hideFooterPagination
                  hideFooterSelectedRowCount 
                  />
                </div>
              </div>
              <div className="absolute top-1/2 left-[30%] transform -translate-x-[20%] -translate-y-1/2">
                <div className="container">
                  <span
                    className={`lock ${handleLocked.med ? "unlocked" : ""}`}
                  ></span>
                </div>
                <p className="flex items-center font-light text-white text-lg mt-2 text-justify h-56">
                  Rileva in modo semplice la sorveglianza sanitaria! Mantieni le
                  visite mediche sempre in ordine, programma nuove visite con
                  facilità e monitora le prescrizioni rilasciate.
                </p>
                <button
                  onMouseOver={() =>
                    setHandleLocked({
                      med: true,
                      sic: false,
                      form: false,
                    })
                  }
                  onMouseOut={() =>
                    setHandleLocked({
                      med: false,
                      sic: false,
                      form: false,
                    })
                  }
                  onClick={() => console.log("Compra Medicina")}
                  className="btnAcquista"
                >
                  <EuroIcon /> ACQUISTA
                </button>
              </div>
            </div>
          </>
        )}

        {sessionStorage.getItem("S") === "1" ? (
          <div class="pieTrue">
            <h3 class="pieTitle">
              <ShieldIcon className="mr-2 text-primary-600" fontSize="large" />
              <b>Sicurezza </b>
            </h3>
            {sicurezzaPie.length > 0 ? (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={sicurezzaPie}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {sicurezzaPie.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} />
                </PieChart>
              </>
            ) : (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={dataEmpty}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {dataEmpty.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} />
                </PieChart>
              </>
            )}
            <hr />
            <div className="w-full md:h-72 mt-2">
              <p className="font-light text-white text-center text-md mt-2">
                PROSSIME SCADENZE
              </p>
               <DataGrid
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={sicurezzaScadenze}
                columns={columnsSicurezza}
                getRowId={(row) => row.ID}
                hideFooterPagination
                    hideFooterSelectedRowCount
              />
            
            </div>
          </div>
        ) : (
          <>
            <div class="pieFalse">
              <div className="opacity-50">
                <h3 class="pieTitle">
                  <ShieldIcon
                    fontSize="large"
                    className="mr-2 text-primary-600"
                  />
                  <b>Sicurezza </b>
                </h3>
              </div>
              <div className="opacity-20">
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={data}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
                <hr />
                <div className="w-full md:h-72 mt-2">
                  <p className="font-light text-white text-center text-md mt-2">
                    PROSSIME SCADENZE
                  </p>
                  <DataGrid
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={dataTable}
                    columns={columnTable}
                    getRowId={(row) => row.value}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                  />
                </div>
              </div>
              <div className="absolute top-1/2 left-[30%] transform -translate-x-[20%] -translate-y-1/2">
                <div className="container">
                  <span
                    className={`lock ${handleLocked.sic ? "unlocked" : ""}`}
                  ></span>
                </div>
                <p className="flex items-center font-light text-white text-lg mt-2 text-justify h-56">
                  Tieni sotto controllo le scadenze di documenti e
                  certificazioni, semplifica la gestione dei dati mantenendo la
                  tua attività sempre in regola rispetto le normative e
                  adempimenti richiesti.
                </p>
                <button
                  onMouseOver={() =>
                    setHandleLocked({
                      med: false,
                      sic: true,
                      form: false,
                    })
                  }
                  onMouseOut={() =>
                    setHandleLocked({
                      med: false,
                      sic: false,
                      form: false,
                    })
                  }
                  onClick={() => console.log("Compra Sicurezza")}
                  className="btnAcquista"
                >
                  <EuroIcon /> ACQUISTA
                </button>
              </div>
            </div>
          </>
        )}

        {sessionStorage.getItem("F") === "1" ? (
          <div class="pieTrue">
            <h3 class="pieTitle">
              <SchoolIcon className="mr-2 text-primary-600" fontSize="large" />
              <b>Formazione </b>
            </h3>
            {analisiFormazione?.length > 0 ? (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={analisiFormazione}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {analisiFormazione.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} />
                </PieChart>
              </>
            ) : (
              <>
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={dataEmpty}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {dataEmpty.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend content={renderLegend} />
                </PieChart>
              </>
            )}
            <hr />
            <div className="w-full md:h-72 mt-2">
              <p className="font-light text-white text-center text-md mt-2">
                PROSSIMI CORSI
              </p>
              <DataGrid
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={prossimiCorsi}
                columns={columns}
                getRowId={(row) => row.ID}
                hideFooterPagination
                hideFooterSelectedRowCount
              />
            </div>
          </div>
        ) : (
          <>
            <div class="pieFalse">
              <div className="opacity-50">
                <h3 class="pieTitle">
                  <SchoolIcon
                    fontSize="large"
                    className="mr-2 text-primary-600"
                  />
                  <b>Formazione </b>
                </h3>
              </div>
              <div className="opacity-20">
                <PieChart width={screenSize / 3 - 100} height={220}>
                  <Legend
                    width={screenSize / 3 - 100}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                  />
                  <Pie
                    data={data}
                    cx={(screenSize / 3 - 50) / 2}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
                <hr />
                <div className="w-full md:h-72 mt-2">
                  <p className="font-light text-white text-center text-md mt-2">
                    PROSSIMI CORSI
                  </p>
                  <DataGrid
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={dataTable}
                    columns={columnTable}
                    getRowId={(row) => row.value}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                  />
                </div>
              </div>
              <div className="absolute top-1/2 left-[30%] transform -translate-x-[20%] -translate-y-1/2">
                <div className="container">
                  <span
                    className={`lock ${handleLocked.form ? "unlocked" : ""}`}
                  ></span>
                </div>
                <p className="flex items-center font-light text-white text-lg mt-2 text-justify h-56">
                  Segui costanetemente lo stato dei corsi: quelli in scadenza,
                  quelli da pianificare e quelli già completati. Ottieni una
                  visione chiara e costante del percorso formativo aziendale.
                </p>
                <button
                  onMouseOver={() =>
                    setHandleLocked({
                      med: false,
                      sic: false,
                      form: true,
                    })
                  }
                  onMouseOut={() =>
                    setHandleLocked({
                      med: false,
                      sic: false,
                      form: false,
                    })
                  }
                  onClick={() => console.log("Compra Formazione")}
                  className="btnAcquista"
                >
                  <EuroIcon /> ACQUISTA
                </button>
              </div>
            </div>
          </>
        )}
      </div> */}

      {/*
      <div className="grid grid-cols-12 gap-3 mt-4">
        
        {
          sessionStorage.getItem("M") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                 
                  Medicina
                
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Lavoratori Visitati
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valMedicina}
                    size={150}
                  />
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Medicina
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Medicina")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
               
              </div>
            </div>
          )

          
        }
        
        {
          sessionStorage.getItem("S") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                 
                  Sicurezza
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Sicurezza
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valSicurezza}
                    size={150}
                  />
                </div>
              </div>
             
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Sicurezza
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Sicurezza")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          )

          
        }
        
        {
          sessionStorage.getItem("F") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Formazione
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Andamento Formazione
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valFormazione}
                    size={150}
                  />
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Formazione
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Formazione")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          )

          
        }
        
      </div> 
      <div className="bg-primary-600 dark:bg-white p-4 sm:p-6 rounded-md mt-4">
        <div className="flex flex-row items-center">
          <h4 className="text-xl md:text-2xl text-white dark:text-primary-600 font-bold mb-1 mr-5 mt-2 my-auto">
            Hai bisogno di aiuto? Chiedi supporto ai nostri operatori!
          </h4>
          <button
            className="flex items-center p-2 text-white bg-primary-100  hover:bg-primary-200 transition duration-300 rounded-md h-20 md:h-16"
            id="Locker"
          >
            <span className="flex items-center p-1">
              <CalendarMonth />
              <p className="pl-3">ORGANIZZA UNA CALL </p>
            </span>
          </button>
        </div>
      </div>
      */}
    </div>
  );
};

export default Home;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color:
            props.value <= 50
              ? "red"
              : props.value <= 75
              ? "#36CAF6"
              : "#51E838",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={props.title}>
          <Typography
            component="div"
            color="text.secondary"
            variant="h4"
            className="cursor-pointer"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
