import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import { DataGridPro, itIT } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const Allegati = (props) => {
  const [allegati, setAllegati] = useState([]);
  const navigate = useNavigate();
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [allegato, setAllegato] = useState();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const downloadFile = async (id, estensione, descrizione, operazione) => {
		try {
			if (loadingDoc) {
		
					alert('Aspetta che la richiesta precedente sia completata prima di iniziare')
	
			} else {
				setLoadingDoc(true);
				// Make the POST request to trigger the download and response from the server
				const response = await unsplash.post(
					'ftp',
					{
						file: id + estensione,
						nomeOriginale: descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione,
						operazione: 'download',
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('jwt')}`,
						},
						responseType: 'blob',
					}
				);
				const url = window.URL.createObjectURL(
					new Blob([response.data], { type: ext[estensione.replace('.', '')] })
				);
				const link = document.createElement('a');
				link.href = url;
				if (operazione === 'download') {
					link.setAttribute(
						'download',
						descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione
					);
					document.body.appendChild(link);
					link.click();
				}

				if (operazione === 'visualizza') {
					if (estensione === '.pdf') {
						window.open(url);
					}
				}
				setLoadingDoc(false);
			}
		} catch (error) {
			let messaggio = 'Errore durante la visualizzazione del file: ';
			if (operazione === 'download') {
				messaggio = 'Errore durante il download del file: ';
			}
      alert(messaggio)
			if (error.response) {
				if (error.response.status === 404) {
					messaggio = messaggio + 'File non trovato.';
				} else if (error.response.status === 500) {
					messaggio =
						messaggio +
						'Errore interno del server. Si prega di riprovare più tardi.';
				} else {
					messaggio = messaggio + 'Errore sconosciuto.';
				}
			} else if (error.request) {
				// La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
				messaggio =
					messaggio +
					'Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.';
			} else {
				// Si è verificato qualche altro errore
				messaggio =
					messaggio +
					'Si è verificato un errore. Si prega di riprovare più tardi.';
			}
      alert(messaggio)
			console.error('Errore durante il download del file:', error);
			setLoadingDoc(false);
		}
	};
  const handleClose = () => {
    setOpenBackDrop(false);
  };

  const columns = [
    {
      field: "Attestato",
      headerName: "Attestato",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return params.row.Attestato > 0 ? (
          <>
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.Attestato,
                  params.row.Estensione,
                  params.row.NomeAttestato,
                  "download"
                )
              }
            >
              <Download
                style={{ height: "2rem", width: "2rem", color: "#11526f" }}
              />
            </IconButton>
            {params.row.Estensione === ".pdf" ? (
              <IconButton
                aria-label="edit"
                onClick={() =>
                  downloadFile(
                    params.row.Attestato,
                    params.row.Estensione,
                    params.row.NomeAttestato,
                    "visualizza"
                  )
                }
              >
                <RemoveRedEye
                  style={{ height: "2rem", width: "2rem", color: "#11526f" }}
                />
              </IconButton>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        );
      },
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.Data
          ? moment(params.row.Data).format("DD/MM/YYYY")
          : "";
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let responseAziendali = await unsplash.get("corsi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { lavoratore: props.idLavoratore },
        });

        setAllegati(responseAziendali.data.data);
      } catch {
        setAllegati([]);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {allegati === undefined ? (
        ""
      ) : (
        <>
          <div className="h-[400px] md:h-[500px] w-full">
            <DataGridPro
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={allegati}
              getRowId={(allegati) => allegati.ID}
              columns={columns}
              disableSelectionOnClick
            />
          </div>
        </>
      )}
    </>
  );
};

export default Allegati;
