import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

export const BreadcrumbContainer = ({ pathname, link }) => {
  const navigate = useNavigate();
  const previusLink = useRef(true);
  const pathMemory = useRef("");

  const [pathTot, setPathTot] = useState([]);

  const handleClick = (linkName, type, event) => {
    if (event) {
      if (
        event.target.id ===
        pathMemory.current[pathMemory.current.length - 1].props.id
      ) {
        return;
      } else {
        navigate(linkName);
      }
    } else {
      if (type === "back") {
        //se è una pagina dettaglio torno indietro
        navigate(linkName);
      } else {
        navigate(`${linkName}`);
      }
    }
  };

  useEffect(() => {
    pathMemory.current = pathTot;
  }, [pathTot]);

  useEffect(() => {
    (() => {
      let pathHistory = [...pathTot];
      //se eseguono un refresh forzo l'aggiunta di Home come primo elemento dell'array
      if (pathHistory.length === 0) {
        pathHistory.push(
          <a
            id="Home"
            key={1}
            color="inherit"
            style={{
              alignItems: "center",
              display: "flex",
            }}
            className="text-underline-hover"
            onClick={() => handleClick("/Dashboard")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </a>
        );
      }
      for (let i = 0; i < pathHistory.length; i++) {
        //se non è "dettaglio" elimino lo sotrico dopo Home
        if (!pathname.includes("dettaglio")) {
          pathHistory = pathHistory.slice(0, 1);
        }
        //se esiste già nella Breadcrumb torno indietro e elimino lo storico
        if (pathHistory[i].props.id === pathname) {
          pathHistory = pathHistory.slice(0, i + 1);
          previusLink.current = false;
        }
      }
      if (previusLink.current) {
        if (pathname === "Home") {
          pathHistory.push(
            <a
              id={pathname}
              key={pathHistory.length + 1}
              color="inherit"
              style={{
                alignItems: "center",
                display: "flex",
              }}
              className="text-underline-hover"
              onClick={() => handleClick("/")}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {pathname}
            </a>
          );
        } else if (pathname.includes("dettaglio")) {
          pathHistory.push(
            <a
              id={pathname}
              key={pathHistory.length + 1}
              color="inherit"
              className="text-underline-hover"
              onClick={(e) => handleClick(-1, "back", e)}
            >
              {link}
            </a>
          );
        } else if (pathname === "") {
          return;
        } else {
          pathHistory.push(
            <a
              id={pathname}
              key={pathHistory.length + 1}
              color="inherit"
              className="text-underline-hover"
              onClick={() => handleClick(pathname)}
            >
              {pathname}
            </a>
          );
        }
      }
      setPathTot(pathHistory);
      previusLink.current = true;
    })();
  }, [pathname]); //tutte le volte che cambia il pathname viene eseguito
  return (
    <>
      <Stack spacing={2} marginLeft={2} className="z-10 ">
        <Breadcrumbs
          sx={{ overflow: "hidden" }}
          className="containerBreadcrumbs"
          separator={
            <NavigateNextIcon fontSize="small" className="dark:text-white" />
          }
          aria-label="breadcrumb"
        >
          {pathTot}
        </Breadcrumbs>
      </Stack>
    </>
  );
};
