import React, { useState, useEffect } from "react";
import ClippedDrawer from "./AppContent";
import Button from "./CustomComponent/Button";
import unsplash from "../api/unsplash";
import {
  Checkbox,
  Grid,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  TextField,
  LinearProgress,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import {
  Error,
  CheckCircle,
  ErrorOutline,
  CheckCircleOutline,
  Delete,
  RemoveRedEye,
  Download,
  Cancel,
} from "@mui/icons-material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const Lavoratori = () => {
  const [loaded, setLoaded] = useState(true);
  const [lavoratori, setLavoratori] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [mansioni, setMansioni] = useState([]);
  const [sedi, setSedi] = useState([]);
  const [lavoratoriBackup, setLavoratoriBackup] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    blu: true,
    attivi: true,
  });
  const [lavoratoreSel, setLavoratoreSel] = useState(null);
  const [filterClienti, setFilterClienti] = useState([]);
  const [filterClientiSel, setFilterClientiSel] = useState([]);

  const downloadStorico = () => {
    document.getElementById("printLavoratori").click();
  };

  const filtroPulsanti = (e, colore) => {
    let f = { ...filtroClessidera };
    f[colore] = e.target.checked;
    setFiltroClessidera(f);

    let lavTemp =
      filterClientiSel.length === 0
        ? lavoratoriBackup
        : lavoratoriBackup.filter((el) =>
            filterClientiSel.includes(el.RagioneSociale)
          );

    if (!f.rosso) {
      lavTemp = lavTemp.filter(
        (v) =>
          v.StatoVisita !== "Scadenza" &&
          v.StatoVisita !== "AccertamentoMancante"
      );
    }

    if (!f.verde) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "OK");
    }

    if (!f.arancione) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "InScadenza");
    }

    if (!f.blu) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "Parziale");
    }

    if (f.attivi) {
      lavTemp = lavTemp.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setLavoratori(lavTemp);
    let lavFilt = [];
    const propertyName =
      lavoratori[0]?.Gruppo !== "Conter" ? "Mansione" : "ClasseDiRischio";
    lavTemp.map((el) => {
      if (lavoratori[0]?.Gruppo === "Conter") {
        return lavFilt.push({
          Scadenza: el.DataScadenza,
          Sede: el.DescrizioneSede,
          RagioneSociale: el.RagioneSociale,
          Cognome: el.Cognome,
          Cognome: el.Cognome,
          Nome: el.Nome,
          [propertyName]: el.MansioneDescr,
          Blocco: el.BloccoMedicina,
          Referente: el.Referente,
          AgenziaDiRiferimento: el.AgenziaDiRiferimento,
          LimitazioniPassate: el.LimitazioniPassate,
          Somministrato: el.Somministrato === 0 ? "No" : "Si",
        });
      } else {
        return lavFilt.push({
          Scadenza: el.DataScadenza,
          Sede: el.DescrizioneSede,
          RagioneSociale: el.RagioneSociale,
          Cognome: el.Cognome,
          Cognome: el.Cognome,
          Nome: el.Nome,
          [propertyName]: el.MansioneDescr,
          Blocco: el.BloccoMedicina,
          Referente: el.Referente,
          LimitazioniPassate: el.LimitazioniPassate,
        });
      }
    });
    setExportData(lavFilt);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);

setVisite(visiteTemp); */
  };

  useEffect(() => {
    (async () => {
      let responseLavoratori = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { user: sessionStorage.getItem("User") },
      });

      setColumns([
        {
          field: "",
          headerName: "*",
          headerAlign: "center",
          align: "center",
          width: 50,
          filterable: false,
          renderCell: (
            params //mi aiuta a mettere il valore
          ) => {
            let el = "";
            if (params.row.StatoVisita === "Scadenza") {
              el = <Error sx={{ color: "#ff5757" }} />;
            } else if (params.row.StatoVisita === "InScadenza") {
              el = <Error sx={{ color: "orange" }} />;
            } else if (params.row.StatoVisita === "Parziale") {
              el = <Error sx={{ color: "#11526F" }} />;
            } else if (params.row.StatoVisita === "AccertamentoMancante") {
              el = <Error sx={{ color: "#ff5757" }} />;
            } else if (params.row.StatoVisita === "OK") {
              el = <CheckCircle sx={{ color: "#51E838" }} />;
            }
            return <div>{el}</div>;
          },
        },
        {
          field: "DataScadenza",
          headerName: "Scadenza",
          headerAlign: "center",
          align: "center",
          width: 150,
          renderCell: (params) => {
            return (
              <div>
                {params.row.DataScadenza
                  ? moment(params.row.DataScadenza).format("DD/MM/YYYY")
                  : ""}
              </div>
            );
          },
        },
        {
          field: "DescrizioneSede",
          headerName: "Sede",
          headerAlign: "center",
          align: "center",
          width: 150,
        },
        {
          field: "RagioneSociale",
          headerName: "Ragione Sociale",
          headerAlign: "center",
          align: "center",
          width: 150,
        },
        {
          field: "Cognome",
          headerName: "Cognome",
          headerAlign: "center",
          align: "center",
          width: 150,
        },
        {
          field: "Nome",
          headerName: "Nome",
          headerAlign: "center",
          align: "center",
          width: 150,
        },

        //condizione per vedere se il gruppo del cliente è Conter
        ...(responseLavoratori.data.data[0]?.Gruppo !== "Conter"
          ? [
              {
                field: "MansioneDescr",
                headerName: "Mansione",
                headerAlign: "center",
                align: "center",
                width: 150,
              },
            ]
          : [
              {
                field: "MansioneDescr",
                headerName: "Classe di Rischio",
                headerAlign: "center",
                align: "center",
                width: 150,
              },
              {
                field: "Somministrato",
                headerName: "Somministrato",
                headerAlign: "center",
                align: "center",
                width: 100,
                renderCell: (params) => {
                  if (params.row.Somministrato === 1)
                    return <CheckCircle sx={{ color: "#51E838" }} />;
                  if (
                    params.row.Somministrato === 0 ||
                    params.row.Somministrato === null
                  )
                    return <Cancel sx={{ color: "#ff5757" }} />;
                },
              },
              {
                field: "BloccoMedicina",
                headerName: "Blocco",
                headerAlign: "center",
                align: "center",
                width: 150,
              },
              {
                field: "Referente",
                headerName: "Referente",
                headerAlign: "center",
                align: "center",
                width: 150,
              },
              {
                field: "AgenziaDiRiferimento",
                headerName: "Agenzia Di Riferimento",
                headerAlign: "center",
                align: "center",
                width: 200,
              },
            ]),
        {
          field: "LimitazioniPassate",
          headerName: "Limitazioni Passate",
          headerAlign: "center",
          align: "center",
          width: 250,
          renderCell: (params) => {
            let fullString = params.row.LimitazioniPassate;
            let splitString = fullString?.split(" - ");
            const modifyArr = splitString?.map((str, index) => {
              if (index === 0) {
                return (str = moment(str).format("DD/MM/YYYY"));
              } else {
                return str;
              }
            });
            return (
              <div>
                {params.row.LimitazioniPassate ? modifyArr.join(" - ") : ""}
              </div>
            );
          },
        },
      ]);
      //se l'utente ha Visualizza Dettaglio flaggato, filtro i lavoratori che hanno la stessa email del Referente
      if (sessionStorage.getItem("Dettaglio") === "1") {
        setLavoratori(
          responseLavoratori.data.data.filter(
            (v) =>
              (!v.DataTermine ||
                moment(v.DataTermine).format("YYYY-MM-DD") >
                  moment(new Date()).format("YYYY-MM-DD")) &&
              sessionStorage.getItem("Email") === v.EmailReferente
          )
        );
        setLavoratoriBackup(
          responseLavoratori.data.data.filter(
            (v) => sessionStorage.getItem("Email") === v.EmailReferente
          )
        );
        //creo array con tutte le ragioni sociali
        const ragSoc = responseLavoratori.data.data
          .filter((v) => sessionStorage.getItem("Email") === v.EmailReferente)
          .map((el) => el.RagioneSociale);
        //tengo solo i non duplicati
        const a = new Set(ragSoc);
        //carico il risultato
        setFilterClienti([...a]);

        let lav = responseLavoratori.data.data.filter(
          (v) =>
            (!v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")) &&
            sessionStorage.getItem("Email") === v.EmailReferente
        );

        let lavFilt = [];
        //utilizzo stinga variabile per Mansione oppure Classe Di Rischio
        const propertyName =
          responseLavoratori.data.data[0]?.Gruppo !== "Conter"
            ? "Mansione"
            : "ClasseDiRischio";
        lav.map((el) => {
          if (responseLavoratori.data.data[0]?.Gruppo === "Conter") {
            return lavFilt.push({
              Scadenza: el.DataScadenza,
              Sede: el.DescrizioneSede,
              RagioneSociale: el.RagioneSociale,
              Cognome: el.Cognome,
              Cognome: el.Cognome,
              Nome: el.Nome,
              [propertyName]: el.MansioneDescr,
              Blocco: el.BloccoMedicina,
              Referente: el.Referente,
              LimitazioniPassate: el.LimitazioniPassate,
              AgenziaDiRiferimento: el.AgenziaDiRiferimento,
              Somministrato: el.Somministrato === 0 ? "No" : "Si",
            });
          } else {
            return lavFilt.push({
              Scadenza: el.DataScadenza,
              Sede: el.DescrizioneSede,
              RagioneSociale: el.RagioneSociale,
              Cognome: el.Cognome,
              Cognome: el.Cognome,
              Nome: el.Nome,
              [propertyName]: el.MansioneDescr,
              Blocco: el.BloccoMedicina,
              Referente: el.Referente,
              LimitazioniPassate: el.LimitazioniPassate,
            });
          }
        });
        setExportData(lavFilt);
      } else {
        setLavoratori(
          responseLavoratori.data.data.filter(
            (v) =>
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
          )
        );
        setLavoratoriBackup(responseLavoratori.data.data);
        //creo array con tutte le ragioni sociali
        const ragSoc = responseLavoratori.data.data.map(
          (el) => el.RagioneSociale
        );
        //tengo solo i non duplicati
        const a = new Set(ragSoc);
        //carico il risultato
        setFilterClienti([...a]);

        let lav = responseLavoratori.data.data.filter(
          (v) =>
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
        );

        let lavFilt = [];
        //utilizzo stinga variabile per Mansione oppure Classe Di Rischio
        const propertyName =
          responseLavoratori.data.data[0]?.Gruppo !== "Conter"
            ? "Mansione"
            : "ClasseDiRischio";
        lav.map((el) => {
          if (responseLavoratori.data.data[0]?.Gruppo === "Conter") {
            return lavFilt.push({
              Scadenza: el.DataScadenza,
              Sede: el.DescrizioneSede,
              RagioneSociale: el.RagioneSociale,
              Cognome: el.Cognome,
              Cognome: el.Cognome,
              Nome: el.Nome,
              [propertyName]: el.MansioneDescr,
              Blocco: el.BloccoMedicina,
              Referente: el.Referente,
              LimitazioniPassate: el.LimitazioniPassate,
              AgenziaDiRiferimento: el.AgenziaDiRiferimento,
              Somministrato: el.Somministrato === 0 ? "No" : "Si",
            });
          } else {
            return lavFilt.push({
              Scadenza: el.DataScadenza,
              Sede: el.DescrizioneSede,
              RagioneSociale: el.RagioneSociale,
              Cognome: el.Cognome,
              Cognome: el.Cognome,
              Nome: el.Nome,
              [propertyName]: el.MansioneDescr,
              Blocco: el.BloccoMedicina,
              Referente: el.Referente,
              LimitazioniPassate: el.LimitazioniPassate,
            });
          }
        });
        setExportData(lavFilt);
        console.log(lavFilt);
      }
      setLoaded(false);

      let responseMansioni = await unsplash.get("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { user: sessionStorage.getItem("User") },
      });
      setMansioni(responseMansioni.data.data);

      let responseSedi = await unsplash.get("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { user: sessionStorage.getItem("User") },
      });
      setSedi(responseSedi.data.data);
    })();
  }, []);

  function CustomToolbar() {
    return (
      <div className="flex flex-wrap justify-center md:justify-start my-2">
        <GridToolbarContainer sx={{ display: "flex", flexWrap: "wrap" }}>
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.rosso}
            checkedIcon={<Error />}
            sx={{ color: "#ff5757!important" }}
            onClick={(e) => filtroPulsanti(e, "rosso")}
          />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.arancione}
            checkedIcon={<Error />}
            sx={{ color: "orange!important" }}
            onClick={(e) => filtroPulsanti(e, "arancione")}
          />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.blu}
            checkedIcon={<Error />}
            sx={{ color: "#11526F!important" }}
            onClick={(e) => filtroPulsanti(e, "blu")}
          />
          <Checkbox
            icon={<CheckCircleOutline />}
            checked={filtroClessidera.verde}
            checkedIcon={<CheckCircle />}
            sx={{ color: "#51E838!important" }}
            onClick={(e) => filtroPulsanti(e, "verde")}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filtroClessidera.attivi}
                onClick={(e) => filtroPulsanti(e, "attivi")}
              />
            }
            label="Solo attivi"
          />
        </GridToolbarContainer>
      </div>
    );
  }
  return (
    <div
      id="bodyDiv"
      className="flex flex-wrap flex-1 h-screen bg-white p-4  shadow-lg rounded-md border border-slate-200"
    >
      <div className="hidden">
        <ReactHtmlTableToExcel
          id="printLavoratori"
          className="download-table-xls-button-none"
          table="table-to-xls"
          filename={"ScadenzarioVisite"}
          sheet={"ScadenzarioVisite"}
          style={{ display: "None!important" }}
          buttonText="Download Log"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ marginTop: 10, display: "none" }}
        cellSpacing={0}
        border={0}
      >
        <tr>
          {exportData.length > 0
            ? Object.keys(exportData[0])?.map((c, index) => (
                <th key={index}>{c}</th>
              ))
            : ""}
        </tr>
        {exportData?.map((c, index) => (
          <tr key={index}>
            {Object.keys(c).map((o, index) => (
              <>
                {o === "Scadenza" || o === "DataTermine" ? (
                  <td key={index}>
                    {c[o] ? moment(c[o]).format("DD/MM/YYYY") : ""}
                  </td>
                ) : (
                  <td key={index}>{c[o]}</td>
                )}
              </>
            ))}
          </tr>
        ))}
      </table>
      <ToastContainer
        toastStyle={{ backgroundColor: "orange" }}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* Same as */}

      <ModalVisiteMediche lavoratoreSel={lavoratoreSel} />
      <div className="flex w-full items-center justify-between flex-wrap md:flex-nowrap gap-4">
        <div className="w-full md:w-1/2">
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={filterClienti}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setFilterClientiSel(newValue);
              setFiltroClessidera({
                verde: true,
                arancione: true,
                rosso: true,
                blu: true,
                attivi: false,
              });
              if (newValue.length > 0) {
                let lavTemp = lavoratoriBackup.filter((el) =>
                  newValue.includes(el.RagioneSociale)
                );
                setLavoratori(lavTemp);
                let lavFilt = [];
                const propertyName =
                  lavoratori[0]?.Gruppo !== "Conter"
                    ? "Mansione"
                    : "ClasseDiRischio";
                lavTemp.map((el) => {
                  if (lavoratori[0]?.Gruppo === "Conter") {
                    return lavFilt.push({
                      Scadenza: el.DataScadenza,
                      Sede: el.DescrizioneSede,
                      RagioneSociale: el.RagioneSociale,
                      Cognome: el.Cognome,
                      Cognome: el.Cognome,
                      Nome: el.Nome,
                      [propertyName]: el.MansioneDescr,
                      Blocco: el.BloccoMedicina,
                      Referente: el.Referente,
                      LimitazioniPassate: el.LimitazioniPassate,
                      AgenziaDiRiferimento: el.AgenziaDiRiferimento,
                      Somministrato: el.Somministrato === 0 ? "No" : "Si",
                    });
                  } else {
                    return lavFilt.push({
                      Scadenza: el.DataScadenza,
                      Sede: el.DescrizioneSede,
                      RagioneSociale: el.RagioneSociale,
                      Cognome: el.Cognome,
                      Cognome: el.Cognome,
                      Nome: el.Nome,
                      [propertyName]: el.MansioneDescr,
                      Blocco: el.BloccoMedicina,
                      Referente: el.Referente,
                      LimitazioniPassate: el.LimitazioniPassate,
                    });
                  }
                });
                setExportData(lavFilt);
              } else {
                setLavoratori(lavoratoriBackup);
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            size="medium"
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clienti"
                placeholder="Ragioni Sociali"
              />
            )}
          />
        </div>
        <div className="w-full xl:w-1/2 flex md:flex-nowrap flex-wrap justify-start xl:justify-between">
          <button onClick={downloadStorico} className="btnExport">
            <FileDownloadIcon /> ESPORTA SCADENZARIO VISITE
          </button>
        </div>
      </div>
      <div className="h-[70vh] md:h-[80vh] w-full">
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          loading={loaded}
          rows={lavoratori}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.ID}
          onRowClick={(params) => setLavoratoreSel(params.row)}
          onRowDoubleClick={() => document.getElementById("elVis").click()}
        />
      </div>
    </div>
  );
};

export default Lavoratori;

const Modal = (props) => {
  const [open, setOpen] = useState(false);
  const [lavoratore, setLavoratore] = useState({
    Mansione: null,
    Nome: null,
    Cognome: null,
    CF: null,
    DataDiNascita: null,
    LuogoNascita: null,
    DataAssunzione: null,
    DataTermine: null,
    Sede: null,
    Ubicazione: null,
    Blocco: null,
  });
  const handleClickOpen = () => {
    if (props.operazione === "Modifica") {
      if (props.lavoratoreSel) {
        setLavoratore(props.lavoratoreSel);
        setOpen(true);
      } else {
        toast.warn("Seleziona almeno un lavoratore", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    let l = { ...lavoratore };
    l[e.target.name] = e.target.text;
    setLavoratore(l);
  };

  const handleChangeDate = (e) => {
    let l = { ...lavoratore };
    l[e.target.name] = e.target.value;
    setLavoratore(l);
  };

  const handleSave = async () => {
    await unsplash.post(
      "lavoratori",
      { lavoratore: lavoratore },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
  };

  const handleChangeAutoComplete = (v) => {
    let l = { ...lavoratore };
    l.Mansione = v;
    setLavoratore(l);
  };

  return (
    <>
      {props.operazione === "Nuovo" ? (
        <Button
          id="ButtonMillerTextWhite"
          text={props.operazione}
          icon="plus-lg"
          size="xs"
          onClick={handleClickOpen}
        />
      ) : (
        <Button
          id="ButtonMillerTextWhite"
          text="Modifica"
          icon="pencil"
          size="xs"
          onClick={handleClickOpen}
        />
      )}

      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-screen flex bg-white bg-opacity-50 justify-center"
        style={{ display: open ? "" : "none" }}
      >
        <div className="relative p-4 w-full mx-auto my-auto flex place-content-center justify-center">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg w-3/6  shadow">
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6 px-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-center text-white">
                {props.operazione}
              </h3>
              {/* <form className="space-y-6 w-full " action="#"> */}

              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 ">
                  <Autocomplete
                    suggestions={props.mansioni}
                    fields={["Descrizione"]}
                    keyField="ID"
                    value={lavoratore?.Mansione}
                    label="Mansione"
                    id="mansioniAut"
                    onChange={handleChangeAutoComplete}
                  />
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5 mt-5">
                    <label
                      htmlFor="fName"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      name="Nome"
                      id="fName"
                      value={lavoratore.Nome}
                      placeholder="Nome"
                      onChange={handleChange}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5 mt-5">
                    <label
                      htmlFor="lName"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Cognome
                    </label>
                    <input
                      type="text"
                      name="Cognome"
                      id="lName"
                      placeholder="Cognome"
                      value={lavoratore.Cognome}
                      onChange={handleChange}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="lName"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      CF
                    </label>
                    <input
                      type="text"
                      name="CF"
                      id="lName"
                      placeholder="CF"
                      value={lavoratore.CF}
                      onChange={handleChange}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="date"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Data di nascita
                    </label>
                    <input
                      type="date"
                      name="DataDiNascita"
                      id="date"
                      value={moment(lavoratore.DataDiNascita).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={handleChangeDate}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="lName"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Luogo di nascita
                    </label>
                    <input
                      type="text"
                      name="LuogoNascita"
                      id="lName"
                      placeholder="Luogo di nascita"
                      value={lavoratore.LuogoNascita}
                      onChange={handleChange}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="date"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Data Assunzione
                    </label>
                    <input
                      type="date"
                      name="DataAssunzione"
                      id="date"
                      value={moment(lavoratore.DataAssunzione).format(
                        "DD-MM-YYYY"
                      )}
                      onChange={handleChangeDate}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="date"
                      className="mb-3 block text-base font-medium text-[#fff]"
                    >
                      Data Termine
                    </label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      value={moment(lavoratore.DataTermine).format(
                        "DD-MM-YYYY"
                      )}
                      onChange={handleChangeDate}
                      className="w-full rounded-lg border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <Autocomplete
                    suggestions={props.sedi}
                    fields={["Descrizione", "Blocco"]}
                    keyField="ID"
                    value={lavoratore?.Sede}
                    label="Sede - Blocco"
                    id="sediAut"
                    onChange={handleChangeAutoComplete}
                  />
                </div>

                <div>
                  <button
                    className=" py-[4px] px-[10px] text-[0.815rem] btnMillerLightBlue"
                    onClick={handleSave}
                  >
                    <span className="flex items-center">
                      <i className={`bi bi-check-lg text-lg`}></i>
                    </span>
                    Salva
                  </button>
                  {/*<Button text="salva" icon="check-lg" size="xs" onClick={handleSave}/>*/}
                </div>
              </div>
              {/*</form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalVisiteMediche = (props) => {
  const [loadingDoc, setLoadingDoc] = useState(false);

  const [open, setOpen] = useState(false);
  const [elencoVisite, setElencoVisite] = useState([]);

  const [columnsVisite, setColumnsVisite] = useState([]);

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      if (loadingDoc) {
        alert(
          "Aspetta che la richiesta precedente sia completata prima di iniziare"
        );
      } else {
        setLoadingDoc(true);
        // Make the POST request to trigger the download and response from the server
        const response = await unsplash.post(
          "ftp",
          {
            file: id + estensione,
            nomeOriginale: descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione,
            operazione: "download",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        );
        const link = document.createElement("a");
        link.href = url;
        if (operazione === "download") {
          link.setAttribute(
            "download",
            descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione
          );
          document.body.appendChild(link);
          link.click();
        }

        if (operazione === "visualizza") {
          if (estensione === ".pdf") {
            window.open(url);
          }
        }
        setLoadingDoc(false);
      }
    } catch (error) {
      let messaggio = "Errore durante la visualizzazione del file: ";
      if (operazione === "download") {
        messaggio = "Errore durante il download del file: ";
      }
      alert(messaggio);
      if (error.response) {
        if (error.response.status === 404) {
          messaggio = messaggio + "File non trovato.";
        } else if (error.response.status === 500) {
          messaggio =
            messaggio +
            "Errore interno del server. Si prega di riprovare più tardi.";
        } else {
          messaggio = messaggio + "Errore sconosciuto.";
        }
      } else if (error.request) {
        // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
        messaggio =
          messaggio +
          "Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
      } else {
        // Si è verificato qualche altro errore
        messaggio =
          messaggio +
          "Si è verificato un errore. Si prega di riprovare più tardi.";
      }
      alert(messaggio);
      console.error("Errore durante il download del file:", error);
      setLoadingDoc(false);
    }
  };

  const handleClickOpen = async () => {
    setOpen(true);

    let responseVisiteMediche = await unsplash.get("visiteMediche", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { lavoratore: props.lavoratoreSel.ID },
    });

    setElencoVisite(responseVisiteMediche.data.data);
    setColumnsVisite([
      {
        field: "Azioni",
        headerName: "Azioni",
        hide: false,
        width: 100,
        align: "center",
        headerAlign: "center",
        renderCell: (
          params //mi aiuta a mettere il valore
        ) =>
          params.row.IDAllegato ? (
            <>
              <IconButton
                aria-label="edit"
                onClick={() =>
                  downloadFile(
                    params.row.IDAllegato,
                    params.row.Estensione,
                    params.row.Descrizione,
                    "download"
                  )
                }
              >
                <Download
                  style={{ height: "2rem", width: "2rem", color: "#11526F" }}
                />
              </IconButton>
              {params.row.Estensione === ".pdf" ? (
                <IconButton
                  aria-label="edit"
                  onClick={() =>
                    downloadFile(
                      params.row.IDAllegato,
                      params.row.Estensione,
                      params.row.Descrizione,
                      "visualizza"
                    )
                  }
                >
                  <RemoveRedEye
                    style={{ height: "2rem", width: "2rem", color: "#11526F" }}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          ),
      },
      {
        field: "Data",
        headerName: "Data",
        width: 150,
        renderCell: (params) => moment(params.row.Data).format("DD/MM/YYYY"),
      },
      { field: "MedicoNomeCompleto", headerName: "Medico", width: 200 },

      //condizione per vedere se il gruppo del cliente è Conter
      ...(props.lavoratoreSel?.Gruppo !== "Conter"
        ? [
            {
              field: "DescMansione",
              headerName: "Mansione",
              headerAlign: "center",
              align: "center",
              width: 150,
            },
          ]
        : [
            {
              field: "DescMansione",
              headerName: "Classe di Rischio",
              headerAlign: "center",
              align: "center",
              width: 250,
            },
            {
              field: "AgenziaDiRiferimento",
              headerName: "Agenzia Di Riferimento",
              headerAlign: "center",
              align: "center",
              width: 200,
            },
          ]),
      { field: "Accertamenti", headerName: "Accertamenti", width: 300 },
      { field: "Note", headerName: "Note", width: 300 },
      { field: "DescEsito", headerName: "Esito", width: 200 },
      { field: "DescTipoVisita", headerName: "Tipo", width: 150 },
      {
        filterable: false,
        field: "PresenzaUnitaMobile",
        headerName: "Presenza Unita Mobile",
        width: 200,
        renderCell: (params) => {
          if (params.row.PresenzaUnitaMobile === 1) {
            return (
              <div>
                <input type="checkbox" checked onClick={false}></input>
              </div>
            );
          } else {
            return (
              <div>
                <input type="checkbox" disabled onClick={false}></input>
              </div>
            );
          }
        },
      },
    ]);
  };

  function CustomToolbar() {
    return (
      <div className="flex flex-wrap justify-center md:justify-start my-2">
        <GridToolbarContainer sx={{ display: "flex", flexWrap: "wrap" }}>
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <>
      <button
        id="elVis"
        style={{ display: "none" }}
        icon="plus-lg"
        size="xs"
        onClick={handleClickOpen}
      />

      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-screen flex bg-gray-600 bg-opacity-50 justify-center"
        style={{ display: open ? "" : "none" }}
        onClick={() => setOpen(false)}
      >
        <div className="relative p-4 w-full mx-auto my-auto flex place-content-center justify-center">
          {/* <!-- Modal content --> */}
          <div
            className="relative bg-white rounded-lg w-full md:w-4/6 shadow"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <svg
                aria-hidden="true"
                className="w-7 h-7"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6  px-3 lg:px-8 ">
              <h3 className="mb-4 text-xl font-medium text-center text-black mx-10 md:mx-0">
                Elenco visite:{" "}
                {props.lavoratoreSel?.Cognome + " " + props.lavoratoreSel?.Nome}
              </h3>
              {/* <form className="space-y-6 w-full " action="#"> */}

              <div className="h-[400px] md:h-[500px] w-full">
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={elencoVisite}
                  columns={columnsVisite}
                  getRowId={(row) => row.ID}
                  disableSelectionOnClick
                />

                <div>
                  {/*<Button text="salva" icon="check-lg" size="xs" onClick={handleSave}/>*/}
                </div>
              </div>
              {/*</form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
