import React, { useState, useRef, useEffect } from "react";

import { Grid, Button, ButtonGroup, MenuItem } from "@mui/material";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridFooterContainer,
  GridRowCount,
  GridFooter
} from "@mui/x-data-grid-pro";

import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const CustomFooterPersonalized = ({ otherChild }) => {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    
  }, [screenSize]);
  return (
    <>
    <GridFooterContainer
    
      sx={{
        display: "flex",
        justifyContent: { xs: "center", md: "flex-start" },
        px: 2,
        flexWrap: { xs: "wrap", md: "nowrap" },
      }}
    >
      {screenSize > "768" ? (
        <>
          <GridToolbarColumnsButton id="GridFooterColumnsButton" />
          <GridToolbarFilterButton
            id="GridFooterColumnsButton"
            style={{
              color: "#11526F",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          />
          <GridToolbarDensitySelector id="GridFooterColumnsButton" />
          <GridToolbarExport id="GridFooterColumnsButton" />
       
          <GridFooter id="CounterFooter" style={{
            float:"right",
            backgroundColor: "transparent",
            boxShadow: "none",
      
            
          }} />
              </>
      ) : (
        <>
          <ButtonGroup
            aria-label="split button"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
          
            <Button
              ref={anchorRef}
              startIcon={
                <MoreVertIcon style={{ height: "28px", width: "28px" }} />
              }
              variant="contained"
              className="ButtonToolbarWrapped"
              onClick={() => {
                handleToggleButton();
              }}
              size="small"
            >
              <span
                style={{
                  margin: "0  0 0 -10px",
                  alignSelf: "center",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Opzioni tabella
              </span>
            </Button>
            <Popper
              sx={{
                zIndex: 100,
              }}
              open={openButton}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper sx={{ width: "181px" }}>
                    <ClickAwayListener onClickAway={handleCloseButton}>
                      <MenuList id="split-button-menu" Item sx={{ p: 0 }}>
                        <MenuItem
                          key={1}
                          onClick={handleToggleButton}
                          sx={{ p: 0 }}
                        >
                          <GridToolbarColumnsButton
                            sx={{ color: "#11526F" }}
                            style={{
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 20px ",
                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                        <MenuItem
                          key={0}
                          onClick={handleToggleButton}
                          sx={{ p: 0 }}
                        >
                          <GridToolbarFilterButton
                            sx={{ color: "#11526F" }}
                            style={{
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 15px ",

                              justifyContent: "flex-start",
                            }}
                            fullWidth
                          />
                        </MenuItem>

                        <MenuItem key={2} sx={{ p: 0 }}>
                          <GridToolbarDensitySelector
                            sx={{ color: "#11526F" }}
                            style={{
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 18px ",

                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                        <MenuItem key={3} sx={{ p: 0 }}>
                          <GridToolbarExport
                            sx={{ color: "#11526F" }}
                            style={{
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 18px ",

                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            
          </ButtonGroup>
        </>
      )}
      {/*  */}
    </GridFooterContainer>
 

      </>
  );
};
